
import {defineComponent, PropType} from "vue";
import {Ticket} from "@/models/flight_models";

export default defineComponent({
  name: "booking_steps",
  props: {
    'activeTab': {} as any,
    'ticket': {type: Object as PropType<Ticket>}
  },
})
