import api from "@/services/api_request";
import { LocalDb } from "@/repository/localdb";
import { FlightPaymentDataRequest, FlightPaymentResponse, FlightPaymentStatusResponse } from "@/models/payment_models";

export const PaymentService = {
    getPaymentData: async (request: any): Promise<FlightPaymentResponse> => {
        const tokenData = LocalDb.getToken();
        const data = await api.post('/flight/getpaymentinfo', request, { headers: { 'Authorization': 'bearer ' + tokenData?.token } })
            .then(response => {
                return response;
            })
        return data;
    },
    getPaymentRailway: async (request: any) => {
        try {
            const tokenData = LocalDb.getToken();
            const data = await api.post('/railway/payment-info', request, { headers: { 'Authorization': 'bearer ' + tokenData?.token } })
                .then(response => {
                    return response;
                })
            return data;
        } catch (error) {
            console.log(error);

        }
    },
    getPaymentFromBalance: async (request: any) => {
        try {
            const tokenData = LocalDb.getToken();
            const data = await api.post('/Payment/order', request, { headers: { 'Authorization': 'bearer ' + tokenData?.token } })
                .then(response => {
                    return response;
                })
            return data;
        } catch (error) {
            console.log(error);

        }
    },
    checkPaymentStatus: async (request: string): Promise<FlightPaymentStatusResponse> => {
        const tokenData = LocalDb.getToken();
        const data = await api.get('/flight/checkpaymentstatus?orderId=' + request, { headers: { 'Authorization': 'bearer ' + tokenData?.token } })
            .then(response => {
                return response;
            })
        return data;
    },
};
