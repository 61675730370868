
import {defineComponent} from "vue";

import TheFooter from "@/components/TheFooter.vue";
import Loader from "@/components/general/loader.vue";
import {AvioneMessage} from "@/constants/error_messages";
import BookingSteps from "@/components/flight_booking/steps.vue";
import {PaymentService} from "@/services/payment_service";
import {ResponseStatusType, StatusEnum} from "@/constants/response_status_type";
import Swal from "sweetalert2";

export default defineComponent({
  data() {
    return {
      loading: false,
      showSuccessContent: false,
      loadingMessage: this.$t(AvioneMessage.PAYMENT_STATUS_CHECKING),
    }
  },
  components: {
    TheFooter,
    Loader,
    BookingSteps,
  },
  created() {
    this.loading = true;
    const orderId = this.$route.params.id;
    if (orderId) {
      PaymentService.checkPaymentStatus(orderId.toString()).then((response) => {
        this.loading = false;
        if (response.status == ResponseStatusType.Ok) {
          if (response.data.status == StatusEnum.Ok) {
            this.showSuccessContent = true;
          } else {
            Swal.fire('', AvioneMessage.PAYMENT_COULD_NOT_CONFIRM, 'error').then(() => {
              if (response.data.status != StatusEnum.Ok) {
                window.location.href = "/profile/orders/" + orderId;
              }
            });
          }
        }
      });
    }
  },
  methods: {
    onGoToMyOrderPage: () => {
      window.location.href = "/profile/orders/";
    }
  }
});
